import React from 'react';
import PropTypes from 'prop-types';

import Seo from '../components/Seo';
import ImageSlices from '../components/ImageSlices';

const BasicPage = ({ data, title, image, description }) => (
  <div
    data-aos="fade-in"
    data-aos-delay="150"
  >
    <h1 className="page-header">{title}</h1>
    <div className="page-content">
      {data.prismicBasicPage.data.body.map(slice => (
        <div
          key={slice.id.toString()}
        >
          {(() => {
            switch (slice.slice_type) {
              case 'text':
                return (
                  <div
                    dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}
                  />
                );
              case 'image_with_caption':
                return (
                  <ImageSlices
                    images={slice.items}
                    prismicId={slice.id}
                  />
                );
              default:
                return '';
            }
          })()}
        </div>
      ))}
    </div>
    <Seo
      slug={data.prismicBasicPage.url}
      title={title}
      image={image}
      description={description}
    />
  </div>
);

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string.isRequired,
};

export default BasicPage;
