import React from 'react';
import { graphql } from 'gatsby';
import BasicPage from '../templates/Page';
import { withPreview } from 'gatsby-source-prismic';

const BiographyPage = ({ data }) => {
  const title = 'Biography';
  const image = 'https://jeanlucponty.cdn.prismic.io/jeanlucponty/5b99b0d10eaa11b78e3de13e845cf71a338b6729_jlp-zappa-600.jpg';
  const description = 'Ponty was born in a family of classical musicians on September 29, 1942 in Avranches, France. His father taught violin, his mother taught piano...';

  return (
    <BasicPage
      data={data}
      title={title}
      image={image}
      description={description}
    />
  );
};

export default withPreview(BiographyPage);

export const query = graphql`
  query {
    prismicBasicPage(
      prismicId: {
        eq: "W28F8CgAAMbICSax"
      }
    ) {
      prismicId
      data {
        page_title {
          html
          text
        }
        body {
          __typename
            ...on PrismicBasicPageBodyText {
              id
              slice_type
              primary {
                text {
                  html
                }
              }
            }
            ...on PrismicBasicPageBodyImageWithCaption {
              id
              slice_type
              items {
                illustration {
                  url
                }
              }
            }
        }
      }
    }
  }
`;
