import React from 'react';
import PropTypes from 'prop-types';

import ImageSlice from './ImageSlice';

const ImageSlices = ({ images, prismicId }) => (
  <div
    className="image-slice"
    key={prismicId.toString()}
  >
    {images.map((image, index) => (
      <div
        key={index}
      >
        <ImageSlice image={image} />
      </div>
    ))}
  </div>
);

ImageSlices.propTypes = {
  images: PropTypes.array.isRequired,
  prismicId: PropTypes.string.isRequired,
};

export default ImageSlices;
