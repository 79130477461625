import React from 'react';
import PropTypes from 'prop-types';

const ImageSlice = ({ image }) => (
  <figure className="content">
    <img src={image.illustration.url} alt="" />
  </figure>
);

ImageSlice.propTypes = {
  image: PropTypes.object,
};

export default ImageSlice;
